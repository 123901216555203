<template>
    <div class="p-relative">
        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="status"
        >
        </FilterPanel>

        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="entities"
            :item-class="itemRowBackground"
            :options.sync="options"
            :server-items-length="total"
            :items-per-page="10"
            :footer-props="{ itemsPerPageOptions:[10, 20, 30] }"
            :page.sync="options.page"
            @page-count="paginationPageCount = $event"
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Documents.ReportGeneration.Query', common: data.item.iscommon })">
            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.theme`]="{ item }">
                <div class="elipsis-text-wrap">
                    <v-doc-type-chip v-if="item.issubstitution" :type="'Substitution'" :text="$t('Замещение')"/>
                    <v-common-chip v-if="item.iscommon === true && item.isown === false" :document-type="item.doctypeof"/>
                    <v-doc-type-chip :type="'ReportQuery'" :text="$t('Отчет')"/>
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.theme">
                            {{item.theme}}
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:[`item.executername`]="{ item }">
                <v-workplace-chip v-if="item.executername" :id="item.executerid" :name="item.executername" />
            </template>

            <template v-slot:[`item.authorname`]="{ item }">
                <v-employee-chip v-if="item.authorname" :id="item.authorid" :name="item.authorname" />
            </template>

            <template v-slot:[`item.enterprisename`]="{ item }">
                <v-enterprise-chip v-if="item.enterprisename" :id="item.enterpriseid" :name="item.enterprisename" />
            </template>
            
            <template v-slot:[`item.createdate`]="{ item }">
                {{ formatDate(item.createdate) }}
            </template>

            <template v-slot:[`item.controldate`]="{ item }">
                {{ formatDate(item.controldate) }}
            </template>

            <template v-slot:[`item.controldays`]="{ item }">
                <StatusChip :status="avaibleStatuses[$helpers.getTaskStatus(item.workstatus)]"/>
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>

        </v-data-table>

    </div>

</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import _ from 'lodash';
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';
import StatusChip from '@/components/global/chips/StatusChip';
import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/saylau/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';

export default {
    columnIndex:  {
        'theme': 4,
        'regnumber': 2,
        'authorname': 8,
        'createdate': 3,
        'controldate': 5,
        'enterprisename': 10,
        'executername':12
    },
    components: {
        Toolbar,
        SearchPanel,
        StatusChip,
        FilterPanel,
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
            filterDataSource: null,
        }
    },
    computed: {
        headers() {
            let headers =  [
                { text: i18n.t("Номер"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Дата_создания"), value: 'createdate', width: '150px' },
                { text: i18n.t("Краткое_содержание"), value: 'theme' },
                { text: i18n.t("Ответственный"), value: 'executername' },
                { text: i18n.t("Автор"), value: 'authorname', width: '150px' },
                { text: i18n.t("Организация"), value: 'enterprisename', width: '150px' },
                { text: i18n.t("Срок"), value: 'controldate', width: '150px' },
            ];
            //if (this.filter.ReportStatus == 'All')
                headers.push({ text: i18n.t("Статус"), value: 'controldays', sortable: false, width: '120px' });
            return headers;
        },
        search: {
            get: function() {
                return this.$store.getters[`reportsgeneration/lists/allmyreportslist/getSearch`];
            },
            set: _.debounce(function(v) {
                this.$store.commit(`reportsgeneration/lists/allmyreportslist/SET_TABLE_OPTION_PAGE`, 1);
                this.$store.commit(`reportsgeneration/lists/allmyreportslist/SET_SEARCH`, v);
            }, 500)
        },
        status: {
            get: function() {
                return this.$store.getters[`reportsgeneration/lists/allmyreportslist/getStatus`];
            },
            set: _.debounce(function(v) {
                this.$store.commit(`reportsgeneration/lists/allmyreportslist/SET_TABLE_OPTION_PAGE`, 1);
                this.$store.commit(`reportsgeneration/lists/allmyreportslist/SET_STATUS`, v);
            }, 500)
        },
        options: {
            get: function() {
                return this.$store.getters['reportsgeneration/lists/allmyreportslist/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('reportsgeneration/lists/allmyreportslist/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['reportsgeneration/getCurrentCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                ReportStatus: this.status
            }
        },
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('reportsgeneration', ['updateCounter']),
        resetPage() {
            this.$store.commit('reportsgeneration/lists/allmyreportslist/SET_TABLE_OPTION_PAGE', 1);
        },
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        }, 
        onToolbarClick(event, btn){
            this[btn.Action](btn);
        },
        async Refresh() {
            await this.loadFilters();
            await this.getData(true);
        },
        async loadFilters() {
            let filterResponse = null;
            filterResponse = await httpAPI({
                url: `api/report/filter?collection=${this.collection}`,
                method: 'GET',
            });

            this.filterDataSource = filterResponse ? filterResponse.data.Payload : null;
        },  
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
            this.setOverlayVisible({ visible: true });    
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/report/list?collection=${this.collection}&filter=${this.getTableFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.Payload?.data ?? [];
            this.total = response?.data?.Payload?.total ?? 0;

            this.loading = false;
            this.updateCounter();
            this.setOverlayVisible({ visible: false });
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, ReportStatus } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "createdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 3;

            return JSON.stringify({ ReportStatus, SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage });
        },
        formatDate (value) {
            if (!value)
                return '';

            return this.$moment(value).format('DD.MM.YYYY');
        }
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
        collection:{
            handler () {
                this.loadFilters();
            }
        }
    },
    async created() {
        let response = await httpAPI({
            url: `api/actions/collection?type=Documents.ReportGeneration`,
            method: 'GET'
        });
        if (response) {
            this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
            this.loadFilters();
        }

    },
}
</script>